<template>
  <v-container fluid tag="error">
  	<v-row align="center" justify="center">
  		<v-img :src="require('@/assets/no-data.png')" aspect-ratio="1" max-width="450"></v-img>
  	</v-row>
  	<v-row align="center" justify="center">
  		<v-col cols="6" class="text-center pb-1">
  			<h1 class="primary--text title-error">Página no disponible</h1>  			
  		</v-col>
  		<v-col cols="12" class="text-center py-0" v-if="$route.query.code && $route.query.code == '500'">
  			<span class="blue-grey--text lighten-5 d-block">El registro que intentas acceder no se encuentra en nuestra base de datos.</span>  			
  		</v-col>
  	</v-row>
  </v-container>
</template>
<script>
	export default{
		name: 'ErrorDashboard',
	}
</script>
<style lang="sass">
	.title-error
		letter-spacing: 2px

</style>